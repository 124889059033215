import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import B2C2 from './B2C2.js';
import BankExport from './BankExport';
import BCB from './BCB';
import { withAuthenticationRequired } from '@auth0/auth0-react';

function Lukka(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="B2C2" value={0} />
                    <Tab label="Signature Bank" value={1} />
                    <Tab label="BCB" value={2} />
                    </Tabs>
                </Box>
                {value === 0 && (
                <Box sx={{ p: 3 }}>
                    <B2C2 />
                </Box>)}
                {value === 1 && (
                    <Box sx={{ p: 3 }}>
                    <BankExport />
                    </Box>
                )}
                {value === 2 && (
                    <Box sx={{ p: 3 }}>
                    <BCB />
                    </Box>
                )}
            </Box>
        </div>
    );
}

export default withAuthenticationRequired(Lukka, {
    onRedirecting: () => (<div>Redirecting you to the login page...</div>)
});